<template lang="pug">
#press.slice-scroll-point.pt-px.-mt-px
  //- div(v-if='! entries.leghth')
    animate-push-on-panel.md_transform.transition.duration-200.slice-body-text.my-70.text-14.md_text-16.xl_text-18.md_px-20.flex.flex-wrap.justify-start.items-start.bold-is-teal
      .w-full.md_w-3x12.px-20.textbody.spacer
      .w-full.px-20.md_w-9x12.textbody
        h3 Our press section is launching soon.
  div(v-if='entries.length')
    //- #opportunities.slice-scroll-point.pt-px.-mt-px
    //-   h2.sr-only Opportunities
    animate-push-on-panel.md_transform.transition.duration-200.slice-body-text.my-70.text-14.md_text-16.xl_text-18.md_px-20.flex.flex-wrap.justify-start.items-start.bold-is-teal
      .w-full.md_w-3x12.px-20.textbody.spacer
      .w-full.px-20.md_w-9x12.textbody
        .press-recent
          h3 Press
          //- pre {{ entries }}

          //- titleRich
          //- indexImage.url
          //- body -> first type: projectBlocks_bodyText_BlockType -> .text
          .flex.flex-wrap.justify-start.items-start
            .item(v-for='(entry, i) in entries.slice(0, 2)', :key='"press_"+i').w-1x2
              //- .w-1x2.mb-20
              //- thumb
              //- pre {{ thumbFormat2(i) }}
              //- pre {{ entry }}
              press-entry-thumb-about.w-full(:entry="entry", :reverseOld="i % 2 === 0", :key="entry.slug", :size="thumbFormat2(i)[0]", :reverse="thumbFormat2(i)[1]")
              //- .sm_w-1x2
              //- text
              //- .w-full.md_w-1x2.px-20.md_px-40
                .textbody
                  h4.text-18.xl_text-20.mb-10 {{ entry.title }}
                  p.mb-20(v-html="entry.body[0].text")
                  //- tags
                  .tags.flex.flex-wrap.mb-20
                    a.btn-theme-text(v-for='tag in entry.tags', :key='tag.slug', :to='`/press/tag/${tag.slug}`') {{ tag.title }}
                  //- date
                  p.text-12.xl_text-14 {{ entry.dateStart }}
                  //- read more
                  router-link.btn-theme-text(:to='`/press/${entry.slug}`') Read More
    slice-call-to-action-row(:slice='PressCTA')
        //- press-entry-thumb.w-full(:entry="entry", :reverseOld="i % 2 === 0", :key="entry.slug", :size="thumbFormat2(i)[0]", :reverse="thumbFormat2(i)[1]", :class="{'sm_w-1x2': thumbFormat2(i)[0] === 'small'}")
</template>

<script>
import PressEntryThumbAbout from '@/components/press/PressEntryThumbAbout'
import SliceCallToActionRow from '@/slices/SliceCallToActionRow'

export default {
  name: 'PressRecent',
  components: { PressEntryThumbAbout, SliceCallToActionRow },
  data () {
    return {
      entries: [],
      doc: null,
      // page should start at 0 for proper pagination offsetting...
      page: this.$route.query.page || 1,
      pageSize: 20,
      feedEnded: false,
      PressCTA: {
        flexLink: {
          url: '/press',
          text: 'More selected press'
        },
        text: 'More selected press',
        boxLink: {
          url: '',
          text: ''
        }
      }
    }
  },
  // computed: {
  //   dateKey () {
  //     return this.$route.name?.includes('-dated') && this.$route.path.split('/').pop()
  //   },
  //   dated () {
  //     if (this.dateKey) {
  //       const today = ymd(new Date())
  //       const dateFilters = {
  //         next: {
  //           dateStart: [`>${today}`],
  //           orderBy: 'dateStart ASC'
  //         },
  //         now: {
  //           dateStart: [`<=${today}`],
  //           dateEnd: [`>${today}`]
  //         },
  //         past: {
  //           dateEnd: [`<${today}`],
  //           orderBy: 'dateEnd DESC'
  //         }
  //       }
  //       return dateFilters[this.dateKey]
  //     }
  //     return undefined
  //   }
  // },
  methods: {
    async getEntries (offset) {
      let params = {
        section: 'press',
        relatedToTags: this.$route.params.tag ? [{ slug: this.$route.params.tag }] : undefined,
        limit: this.pageSize,
        offset: offset || ((this.page - 1) * this.pageSize) // offset will be 0 on first page
      }
      // dated ?
      if (this.dated) {
        params = { ...params, ...this.dated }
      }

      // remove empty params
      Object.keys(params).forEach(key => {
        if (!params[key]) {
          delete params[key]
        }
      })

      // fetch!
      const entries = await this.$store.dispatch('getPressEntries', params)

      if (!entries.length) {
        this.feedEnded = true
      }

      // add
      this.entries = this.entries.concat(entries)
      this.page++
    },
    thumbFormat2 (i) {
      // tagged feeds (read/watch/listen/...) - first two large, the rest small
      // if ((this.$route.name.includes('tagged') && i >= 2) || (this.$route.name.includes('press') && i >= 2)) {
      return ['small', (i % 4 === 0 || i % 4 === 1)]
      // }
      // default: ALL large
      // return [undefined, i % 2 === 0]
    }
  },
  created () {
    this.getEntries()
  }
}
</script>
